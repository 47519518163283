<template>
  <b-container class="m-0 w-100" fluid>
    <header-slot></header-slot>
    <b-nav card-header pills class="m-0">
      <b-nav-item
        exact
        exact-active-class="active"
        :active="
          $route.matched[2].name == 'debt-solution-specialist-ncr-realtor'
        "
        :to="{ name: 'debt-solution-specialist-ncr-realtor' }"
        :link-classes="['px-3', bgTabsNavs]"
      >
        NCR REALTOR
      </b-nav-item>
      <b-nav-item
        exact
        exact-active-class="active"
        :active="
          $route.matched[2].name == 'debt-solution-specialist-ncr-programs'
        "
        :to="{ name: 'debt-solution-specialist-ncr-programs' }"
        :link-classes="['px-3', bgTabsNavs]"
      >
        NCR PROGRAMS
      </b-nav-item>
      <b-nav-item
        exact
        exact-active-class="active"
        :active="$route.matched[2].name == 'request-ncr-debt-solution'"
        :to="{ name: 'request-ncr-debt-solution' }"
        :link-classes="['px-3', bgTabsNavs]"
      >
        REQUEST NCR
      </b-nav-item>
      <b-nav-item
        exact
        exact-active-class="active"
        :active="$route.matched[2].name == 'debtsolution-specialist-dispute'"
        :to="{ name: 'debtsolution-specialist-dispute' }"
        :link-classes="['px-3', bgTabsNavs]"
      >
        DISPUTE
      </b-nav-item>
      <b-nav-item
        exact
        exact-active-class="active"
        :active="
          $route.matched[2].name == 'debt-solution-specialist-dispute-results'
        "
        :to="{ name: 'debt-solution-specialist-dispute-results' }"
        :link-classes="['px-3', bgTabsNavs]"
      >
        UPDATE REQUEST
      </b-nav-item>
      <b-nav-item
        exact
        exact-active-class="active"
        :active="$route.matched[2].name == 'request-workplan-debt-solution'"
        :to="{ name: 'request-workplan-debt-solution' }"
        :link-classes="['px-3', bgTabsNavs]"
      >
        REQUEST WORKPLAN
      </b-nav-item>
    </b-nav>
    <b-card class="border-top-primary border-3 border-table-radius px-0">
      <router-view />
    </b-card>
  </b-container>
</template>
<script>
import NcrRealtor from "@/views/debt-solution/views/ncr-realtor/views/NcrRealtorTemplate.vue";

export default {
  components: { NcrRealtor },
};
</script>